import RcTableViewUtils from "../../../../../../shared/table/RcTableViewUtils";

// import TextAreaUpdateRow from "@/portals/shared/cell/textarea/TextAreaUpdateRow.js";
// import IntegerUpdateRow from "@/portals/shared/cell/integer/IntegerUpdateRow.js";

import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
// import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";
// import StringDisplayRow from "@/portals/shared/cell//string/StringDisplayRow";
import StringUpdateRow from "@/portals/shared/cell/string/StringUpdateRow.js";
import StringListUpdateRow from "@/portals/shared/cell/string/list/StringListUpdateRow.js";
import LabelValueDisplayRow from "@/portals/shared/cell//label/LabelValueDisplayRow";
import LabelDisplayRow from "@/portals/shared/cell//label/LabelDisplayRow";
import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";
import SpacerDisplayRow from "@/portals/shared/cell/spacer/SpacerDisplayRow";
// import StatusDisplayRow from "@/portals/shared/cell/status/StatusDisplayRow.js";
import DateUpdateRow from "@/portals/shared/cell/date/DateUpdateRow.js";
// import DefectTypeDisplayRow from "@/portals/shared/cell/defecttype/DefectTypeDisplayRow.js";
// import StateDisplayRow from "@/portals/shared/cell/state/StateDisplayRow.js";
import JurisdictionUpdateRow from "@/portals/shared/cell/vehicle/jurisdiction/JurisdictionUpdateRow.js";
import EmailUpdateRow from "@/portals/shared/cell/email/EmailUpdateRow.js";
import PhoneNumberUpdateRow from "@/portals/shared/cell/phonenumber/PhoneNumberUpdateRow.js";
// import CheckboxUpdateRow from "@/portals/shared/cell/checkbox/CheckboxUpdateRow.js"
// import PictureUpdateRow from "@/portals/shared/cell/picture/PictureUpdateRow.js";
// import CancellingDisplayRow from "@/portals/shared/cell/cancelling/CancellingDisplayRow.js";

import MC from "@/domain/session/MC.js";
import BoxDisplayRow from "../../../../../../shared/cell/box/BoxDisplayRow";

import StringUtils from '@/utils/StringUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';

export default class UpdateViewUtils extends RcTableViewUtils {

  constructor(model, tableData) {
    super(model, tableData);
    this.MC = new MC();
    this._user = model.user();
    this._employee = model.employee();
    this.SU = StringUtils;
    this.CU = ContentUtils;
  }

  employee() {
    return this._employee;
  }
  
  user() {
    return this._user;
  }

  start() {
    if (this.model().isStatePageLoading()) {
      super.start("");
      return;
    }

    if (this.model().isStateContentSaving()) {
      this.renderSaving();
      return;
    }

    if (this.model().isStateContentSaved()) {
      this.renderSaved();
      return;
    }

    this.renderHeader();

    if (this.model().isStateContentLoading()) {
      super.start("Loading User Properties");
      return;
    }

    if (this.model().isStateContentLoaded()) {
      this.renderBody();
    }

    this.renderFooter();
  } 

  renderBody() {
    var body = new BoxDisplayRow();
    var isEditingSelf = this.user().id() === this.model().domain().session().user().id();
    var roles = ContentUtils.ROLES();
    var editSelfMessage = null;
    var roleDisplay = new StringListUpdateRow().withMandatoryValue(true).withOptions(roles).withName("Role")
                                              .withDefaultValue(this.employee().roles().find().first().id()).withField("UserRoleMap")
                                              .withKey("UserRoleMap");
    if(isEditingSelf) {
      var roleId = this.employee().roles().find().first().id();
      var role = roles.find(dict => dict['@rid'] === roleId);
      roleDisplay = new LabelValueDisplayRow().withLabel("Role").withValue(role.name);
      editSelfMessage = new LabelDisplayRow().withHint("An owner or manager may not change their own role. Another owner or manager will need to make this change for you to prevent accidental account lockout.");
    }
    body.withChild(new RowDisplayRow()
                          .withChild(new BoxDisplayRow().withBorder(true).withWidth(8)
                                          .withChild(new TitleDisplayRow().withTitle("User Details").withSubtitle(true))
                                          .withChild(new StringUpdateRow().withName("First Name").withField("first").withKey("first").withDefaultValue(this.user().first()).withMandatoryValue(true))
                                          .withChild(new StringUpdateRow().withName("Last Name").withField("last").withKey("last").withDefaultValue(this.user().last()).withMandatoryValue(true))
                                          .withChild(new EmailUpdateRow().withName("Email").withField("email").withKey("email").withDefaultValue(this.user().email()).withMandatoryValue(true))
                                          .withChild(new PhoneNumberUpdateRow().withName("Phone No.").withField("phone").withKey("phone").withDefaultValue(this.user().phone()).withMandatoryValue(false))
                          )
                          .withChild(new BoxDisplayRow().withBorder(true).withWidth(7)
                                          .withChild(new TitleDisplayRow().withTitle("Role and Employee ID").withSubtitle(true))
                                          .withChild(new LabelDisplayRow().withLabel("Every user must have a role, a manager or and owner have the most permissions, they can perform inspections as well as use this portal"))
                                          .withChild(roleDisplay)
                                          .withChild(editSelfMessage)
                                          .withChild(new StringUpdateRow()
                                                          .withMandatoryValue(false)
                                                          .withDefaultValue(this.employee().employmentId())
                                                          .withHint("Employee ID is what will be used to sign into the shared devices (tablets or phones)")
                                                          .withName("Employee ID")
                                                          .withField("employmentId")
                                                          .withKey("employmentId"))
                          )
      );
    body.withChild(new RowDisplayRow()
                          .withChild(new BoxDisplayRow().withBorder(true).withWidth(8)
                                          .withChild(new TitleDisplayRow().withTitle("Address Information").withSubtitle(true))
                                          .withChild(new LabelDisplayRow().withLabel("If the user takes the vehicle home at night, complete this section. This will provide the user with an easy to use button in the app to set their address when completing inspections"))
                                          .withChild(new StringUpdateRow().withName("Street").withField("address").withKey("address").withDefaultValue(this.user().street()).withMandatoryValue(false))
                                          .withChild(new StringUpdateRow().withName("City").withField("city").withKey("city").withDefaultValue(this.user().city()).withMandatoryValue(false))
                                          .withChild(new JurisdictionUpdateRow()
                                                          .withName(this.MC.Vehicle.Add.Jurisdiction.value())
                                                          .withDefaultValue(this.user().jurisdiction())
                                                          .withField("jurisdiction")
                                                          .withKey("jurisdiction")
                                                          .withMandatoryValue(false))
                                          .withChild(new StringListUpdateRow()
                                                          .withOptions(ContentUtils.COUNTRYS())
                                                          .withName("Country")
                                                          .withDefaultValue(this.user().country())
                                                          .withField("country")
                                                          .withKey("country"))
                                          .withChild(new StringUpdateRow().withName("Postal Code").withField("postal").withKey("postal").withDefaultValue(this.user().postal()).withMandatoryValue(false))
                          )
                          .withChild(new BoxDisplayRow().withWidth(7)
                                          .withChild(new BoxDisplayRow().withBorder(true)
                                                          .withChild(new TitleDisplayRow().withTitle("Drivers License Information").withSubtitle(true))
                                                          .withChild(new LabelDisplayRow().withLabel("With this information the portal will show you a message when the license is about to expire"))
                                                          .withChild(new StringListUpdateRow()
                                                                          .withOptions(ContentUtils.LICENSES())
                                                                          .withName("Class")
                                                                          .withDefaultValue(this.user().licenseClass())
                                                                          .withField("licenseClass")
                                                                          .withKey("licenseClass"))
                                                          .withChild(new DateUpdateRow()
                                                                          .withMandatoryValue(false)
                                                                          .withDefaultValue(this.user().licenseExpiryAsString())
                                                                          .withName("Expiry Date")
                                                                          .withField("licenseExpiry")
                                                                          .withKey("licenseExpiry")))
                                          .withChild(new SpacerDisplayRow())
                                          .withChild(new BoxDisplayRow().withBorder(true)
                                                          .withChild(new TitleDisplayRow().withTitle("Employment Start and End Information").withSubtitle(true))
                                                          .withChild(new LabelDisplayRow().withLabel("When did this person start and stop working for your company."))
                                                          .withChild(new DateUpdateRow()
                                                              .withMandatoryValue(false)
                                                              .withDefaultValue(this.employee().startDate())
                                                              .withName("Start Date")
                                                              .withField("startDate")
                                                              .withKey("startDate"))
                                                          .withChild(new DateUpdateRow()
                                                              .withMandatoryValue(false)
                                                              .withDefaultValue(this.employee().endDate())
                                                              .withName("End Date")
                                                              .withField("endDate")
                                                              .withKey("endDate"))
                                          )
                          )
      );
    this.addBody(body);
  }

  renderHeader() {
    this.addHeader(new TitleDisplayRow().withTitle("Update User: " + this.model().user().fullName()));
  }

  renderFooter() {
    this.addFooter(
      new RowDisplayRow()
      .withFixedPosition(true)
      .withChildren([
        new ButtonDisplayRow()
        .withWidth(3)
        .withName("Cancel")
        .withField("Close"),

        new SpacerDisplayRow().withWidth(9),

        new ButtonDisplayRow()
          .withBackgroundPrimaryAction()
          .withName("Save")
          .withField("Save")
          .withWidth(3),
      ])
    );
  }
}