<template>
    <Box>
  
      <DisplayRow v-if="this.showDialog == 'userDetails'" :itemId="this.selectedUserId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue" :isAdmin="isAdmin"></DisplayRow>
      <TimesheetListRow v-if="this.showDialog == 'timesheetsDialog'" :userId="this.selectedUserId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue" :isAdmin="isAdmin"></TimesheetListRow>
      <InspectionListRow v-if="this.showDialog == 'inspectionsDialog'" :userId="this.selectedUserId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue" :isAdmin="isAdmin"></InspectionListRow>
      <UpdateRow v-if="this.showDialog == 'updateDialog'" :userId="this.selectedUserId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue" :isAdmin="isAdmin" :showButtons="false"></UpdateRow>
      <AddRow v-if="this.showDialog == 'addDialog'" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue" :isAdmin="isAdmin" :showButtons="false"></AddRow>
      <InviteRow v-if="this.showDialog == 'inviteDialog'" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue" :isAdmin="isAdmin" :showButtons="false"></InviteRow>
      <WorkingDisplayRow v-if="this.showDialog == 'workingDialog'"></WorkingDisplayRow>
      <UserRemoveConfirmDialogRow v-if="this.showDialog == 'removeDialog'" :userId="this.selectedUserId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue" :isAdmin="isAdmin"></UserRemoveConfirmDialogRow>
      <ResendConfirmDialogRow v-if="this.showDialog == 'resendDialog'" :userId="this.selectedUserId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue" :isAdmin="isAdmin"></ResendConfirmDialogRow>
      <SendConfirmDialogRow v-if="this.showDialog == 'sendDialog'" :userId="this.selectedUserId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue" :isAdmin="isAdmin"></SendConfirmDialogRow>
      <RestoreConfirmDialogRow v-if="this.showDialog == 'restoreDialog'" :userId="this.selectedUserId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue" :isAdmin="isAdmin"></RestoreConfirmDialogRow>
      <PasswordResetConfirmDialogRow v-if="this.showDialog == 'passwordDialog'" :userId="this.selectedUserId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue" :isAdmin="isAdmin"></PasswordResetConfirmDialogRow>
      
      <Row>
        <Column :width="15">
          <Box class="rc-border-thin" :key="redraw + 1">
            <Row v-for="(row, rowIndex) in headerData" :key="rowIndex" >
              <CellFactory 
                :rowData="row.data"
                :showMissingRequirements="showMissingRequirements"
                v-on:withEnterPressed="withEnterPressed"
                v-on:withValue="withValue"
                v-on:withButtonPressed="withButtonPressed"
                  />
            </Row>
          </Box>
        </Column>
      </Row>

      <Row >
        <Column :width="15" >
          <Box :key="redraw" class="full-height-scrollable-div rc-border-thin">
            <Row v-for="(row, rowIndex) in viewData" :key="rowIndex" >
              <CellFactory 
                :rowData="row.data"
                :showMissingRequirements="showMissingRequirements"
                v-on:withEnterPressed="withEnterPressed"
                v-on:withValue="withValue"
                v-on:withButtonPressed="withButtonPressed"
                  />
            </Row>
          </Box>
        </Column>
      </Row>
  
      <Row>
        <Column :width="15">
          <Box class="rc-border-thin" :key="redraw + 2">
            <Row v-for="(row, rowIndex) in footerData" :key="rowIndex" >
              <CellFactory 
                :rowData="row.data"
                :showMissingRequirements="showMissingRequirements"
                v-on:withEnterPressed="withEnterPressed"
                v-on:withValue="withValue"
                v-on:withButtonPressed="withButtonPressed"
                  />
            </Row>
          </Box>
        </Column>
      </Row>
  
    </Box>
    
  </template>
  
  <script>
  
import { mapActions, mapGetters } from 'vuex';
  
import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';
  
import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";
  
import ListViewModel from "./ListViewModel.js";
import ListViewUtils from "./ListViewUtils.js";
  
import CellFactory from "@/portals/shared/cell/CellFactory.vue";
import TimesheetListRow from './timesheet/TimesheetListRow.vue';
import InspectionListRow from './inspection/InspectionListRow.vue';
import AddRow    from './add/AddRow.vue';
import UpdateRow from './update/UpdateRow.vue';
import InviteRow from './invite/InviteRow.vue';
import WorkingDisplayRow from '@/portals/shared/views/working/WorkingDisplayRow.vue';
import UserRemoveConfirmDialogRow from "./remove/RemoveConfirmDialogRow.vue";
import PasswordResetConfirmDialogRow from "./reset/ResetConfirmDialogRow.vue";
import ResendConfirmDialogRow from './resend/ResendConfirmDialogRow.vue';
import SendConfirmDialogRow from './send/SendConfirmDialogRow.vue';
import RestoreConfirmDialogRow from './restore/RestoreConfirmDialogRow.vue';
import DisplayRow from './display/DisplayRow.vue';

export default {
    name: "employee-list-view",
    components: {
      Column, Box, Row,
      CellFactory,
      AddRow,
      UpdateRow,
      InviteRow,
      TimesheetListRow,
      InspectionListRow,
      WorkingDisplayRow,
      UserRemoveConfirmDialogRow,
      PasswordResetConfirmDialogRow,
      ResendConfirmDialogRow,
      SendConfirmDialogRow,
      RestoreConfirmDialogRow,
      DisplayRow,
    },
    props: {
      adminDisplay: { type: Boolean, default: false },
      isAdmin: {type: Boolean, default: false},
      usedFor: {type: String, default: ""},
      embedded: { type: Boolean, default: false }
    },
    data() {
      return {
        redraw: 1,
        
        MC: new MC(),
        Strings: StringUtils,
        Contents: ContentUtils,
        Consts: ConstUtils,
        
        CellFactory: CellFactory,
        
        viewModel: null,
        viewData: [],
        headerData: [],
        footerData: [],
        showMissingRequirements: false,
        showDialog: "",
        selectedUserId: "",

        ListViewModel: ListViewModel,
        ListViewUtils: ListViewUtils,
      };
    },
    computed: {
      ...mapGetters([
                     'auth_connected',
                     'auth_socket_status',
                     'signin_event',
                     'auth_client',
                     'domain',
                     ]),
    },
    watch: {
      auth_connected() {
        ConnectionUtils.sendSignInEvent(this);
      },
      auth_socket_status() {
        ConnectionUtils.displayStatus(this);
      },
      signin_event() {
        ConnectionUtils.createDomainSession(this);
      },
      $route (to, from){
        if (to && from) {
          if (to.query["employeeListAction"] === "userDetails") {
            this.selectedUserId = to.query["userId"];
            this.showDialog = "userDetails";

          } else if (to.query["employeeListAction"] === "userHours") {
            this.selectedUserId = to.query["userId"];
            this.showDialog = "userDetails";

          } else if (to.query["employeeListAction"] === "userInspections") {
            this.selectedUserId = to.query["userId"];
            this.showDialog = "userDetails";

          } else if (to.query["employeeListAction"] === "updateDialog") {
            this.selectedUserId = to.query["userId"];
            this.showDialog = "updateDialog";

          } else if (to.query["employeeListAction"] === "removeDialog") {
            this.selectedUserId = to.query["userId"];
            this.showDialog = "removeDialog";

          } else if (to.query["employeeListAction"] === "addDialog") {
            this.showDialog = "addDialog";

          } else if (to.query["employeeListAction"] === "inviteDialog") {
            this.showDialog = "inviteDialog";

          } else if (to.query["employeeListAction"] === "passwordDialog") {
            this.selectedUserId = to.query["userId"];
            this.showDialog = "passwordDialog";

          } else if (to.query["employeeListAction"] === "sendDialog") {
            this.selectedUserId = to.query["userId"];
            this.showDialog = "sendDialog";

          } else if (to.query["employeeListAction"] === "restoreDialog") {
            this.selectedUserId = to.query["userId"];
            this.showDialog = "restoreDialog";

          } else if (to.query["employeeListAction"] === "resendDialog") {
            this.selectedUserId = to.query["userId"];
            this.showDialog = "resendDialog";

          } else if (to.query["employeeListAction"] === "update") {
            this.selectedUserId = to.query["userId"];

          } else if (to.query["employeeListAction"] === "email") {
            this.selectedUserId = to.query["userId"];
            this.showDialog = "emailDialog";

          } else if (to.query["employeeListAction"] === "remarks") {
            this.selectedUserId = to.query["userId"];
            this.showDialog = "remarksDialog";

          } else {
            let refresh = false;

            var toQuery = Object.assign({}, to.query);
            var fromQuery = Object.assign({}, from.query);
            var employeeListAction = fromQuery["employeeListAction"];
            var employeeInviteAction = fromQuery["employeeInviteAction"];

            delete toQuery.employeeListAction;
            delete toQuery.userId;
            delete toQuery.employeeInviteAction;
            delete toQuery.equipmentId;

            delete fromQuery.employeeListAction;
            delete fromQuery.userId;
            delete fromQuery.employeeInviteAction;
            delete fromQuery.equipmentId;

            if (JSON.stringify(toQuery) !== JSON.stringify(fromQuery) && !employeeListAction) {
              refresh = true;
            }

            if (employeeInviteAction == "save") {
              refresh = true;
            }

            if (StringUtils.isNotEmpty(this.showDialog)) {
              this.showDialog = ""; 
            } 

            if (refresh) {
              this.reloadPage();
            }
          }
        }
      }
    },
    mounted: function () {
      this.viewModel = new this.ListViewModel(this);
      this.viewModel.withStatePageLoading();
      this.viewModel.clearAndBuildTableData();
      this.viewModel.start();
      ConnectionUtils.ensureConnection(this);
    },
    methods: {
      ...mapActions([
                     'AuthStorage_updateCompany',
                     'addListenerAction',
                     'removeListenerAction',
                     'updateDomain',
                     ]),
  
      start: function() {
        ConnectionUtils.ensureCompanyLoaded(this, "billing/display");
        this.viewModel.withStateContentLoading();
        this.viewModel.clearAndBuildTableData();
        this.paint();
        this.viewModel.loadEmployees();
      },
  
      displayProgress: function(msg) {
        this.callback(msg);
      },

      showDialogIfRequired: function() {
        var query = Object.assign({}, this.$route.query);
        this.selectedUserId = query["userId"];
        this.showDialog = query["employeeListAction"];

        if (query["employeeListAction"] === "userHours") {
          this.showDialog = "userDetails";
        }
        if (query["employeeListAction"] === "userInspections") {
          this.showDialog = "userDetails";
        }
      },

      callback: function(msg) {
        var row = this.viewModel.findRowProgress();
        if (row) {
          row.withMessage(msg);
        }
        this.paint();
      },
      
      model: function() {
        return this.viewModel;
      },
      
      withValue: function(valueData) {
        this.model().withValue(valueData);
        if (valueData["field"] === "PageSize") {
          this.model().withPageSize(valueData, "employee");
          this.addQueryParams(valueData, "pageSize");
        }
        if (valueData["field"] === "PageIndex") {
          this.addQueryParams(valueData, "page");
        }
        
        if (valueData["field"] === "Search") {
          this.model().withSearchText(valueData);
        }

        if (valueData["field"] === "Date") {
          this.addQueryParams(valueData, "time")
        }
      },
      
      urlQueryParams() {
        var query = Object.assign({}, this.$route.query);
        if (query == null) {
          query = {};
        }
        return query;
      },

      addQueryParams(valueData, key) {
        var query = this.urlQueryParams();
        this.addQueryParamsToRouter(query, valueData, key);
      },

      addQueryParamsToRouter(query, valueData, key) {
        query[key] = valueData["value"];
        if (key === "pageSize") {
          delete query.page
        }
        this.$router.push({ query });
      },

      withEnterPressed(keyData) {
        if (keyData["title"]) {
          keyData["field"] = "Search"
          this.withButtonPressed(keyData);
        }
      },
      
      withButtonPressed: function(buttonData) {
        this.model().withButtonPressed(buttonData);

        if (buttonData["field"] === "UserDetails") {
          const query = Object.assign({}, this.$route.query);
          query["employeeListAction"] = "userDetails";
          query["userId"] = this.Contents.baseEncode(buttonData["id"]);
          this.$router.push({ query });

        } else if (buttonData["field"] === "UserUpdate") {
          const query = Object.assign({}, this.$route.query);
          query["employeeListAction"] = "updateDialog";
          query["userId"] = this.Contents.baseEncode(buttonData["id"]);
          this.viewModel.pushToRouter(query);

        } else if (buttonData["field"] === "Rebuild") {
          this.showDialog = "workingDialog";

        } else if (buttonData["field"] === "Refresh") {
          this.reloadPage();

        } else if (buttonData["field"] === "UserRemoveConfirm") {
          const query = Object.assign({}, this.$route.query);
          query["employeeListAction"] = "removeDialog";
          query["userId"] = this.Contents.baseEncode(buttonData["id"]);
          this.$router.push({ query });

        } else if (buttonData["field"] === "PasswordResetConfirm") {
          const query = Object.assign({}, this.$route.query);
          query["employeeListAction"] = "passwordDialog";
          query["userId"] = this.Contents.baseEncode(buttonData["id"]);
          this.$router.push({ query });

        } else if (buttonData["field"] === "Restore") {
          const query = Object.assign({}, this.$route.query);
          query["employeeListAction"] = "restoreDialog";
          query["userId"] = this.Contents.baseEncode(buttonData["id"]);
          this.$router.push({ query });

        } else if (buttonData["field"] === "Resend") {
          const query = Object.assign({}, this.$route.query);
          query["employeeListAction"] = "resendDialog";
          query["userId"] = this.Contents.baseEncode(buttonData["id"]);
          this.$router.push({ query });

        } else if (buttonData["field"] === "Send") {
          const query = Object.assign({}, this.$route.query);
          query["employeeListAction"] = "sendDialog";
          query["userId"] = this.Contents.baseEncode(buttonData["id"]);
          this.$router.push({ query });

        } else if (buttonData["field"] === "Invite") {
          // const path = StringUtils.format(ConstUtils.ROUTES.EMPLOYEE.INVITE_PATH, [this.viewModel.company().idEncoded()]);
          // this.$router.push({ path });
          const query = Object.assign({}, this.$route.query);
          query["employeeListAction"] = "inviteDialog";
          this.$router.push({ query });

        } else if (buttonData["field"] === "Add") {
          // const path = StringUtils.format(ConstUtils.ROUTES.EMPLOYEE.ADD_PATH, [this.viewModel.company().idEncoded()]);
          // this.$router.push({ path });
          const query = Object.assign({}, this.$route.query);
          query["employeeListAction"] = "addDialog";
          this.$router.push({ query });

        } else if (buttonData["field"] === "Close") {
          this.showDialog = "";
          var query = Object.assign({}, this.$route.query);
          query = this.deleteDetails(query);
          this.$router.push({ query });

        } else if (buttonData["field"] === "DetailsClose") {
          query = Object.assign({}, this.$route.query);
          query = this.deleteDetails(query);
          this.$router.push({ query });

        } else  if (buttonData["field"] === "Time") {
          this.addQueryParams(buttonData, "time");

        } else if (buttonData["field"] === "State") {
          this.addQueryParams(buttonData, "state");

        } else if (buttonData["field"] === "Full") {
          const fullParams = {
            id: this.Contents.baseDecode(buttonData["id"]),
          };
          this.$router.push({
            name: ConstUtils.ROUTES.EMPLOYEE.DETAILS,
            params: fullParams,
          });

        } else if (buttonData["field"] === "Hours") {
          let query = Object.assign({}, this.$route.query);
          query["employeeListAction"] = "userHours";
          query["userId"] = this.Contents.baseEncode(buttonData["id"]);
          this.$router.push({ query });

        } else if (buttonData["field"] === "Inspections") {
          let query = Object.assign({}, this.$route.query);
          query["employeeListAction"] = "userInspections";
          query["userId"] = this.Contents.baseEncode(buttonData["id"]);
          this.$router.push({ query });
          
        } else if (buttonData["field"] === "NextPage") {
          const query = Object.assign({}, this.$route.query);
          if (query.page) {
            query.page = (Number(query.page) + 1) + "";
          } else {
            query.page = "2";
          }
          if (query.page <= this.model().pages().length) {
            this.$router.push({ query });
          }

        } else if (buttonData["field"] === "PreviousPage") {
          const query = Object.assign({}, this.$route.query);
          if (query.page) {
            query.page = (Number(query.page) - 1) + "";
            if (query.page <= 0) {
              query.page = "1";
            }
            this.$router.push({ query });
          }

        } else if (buttonData["field"] === "Search") {
          buttonData["value"] = this.model().searchText();
          const queryParams = this.urlQueryParams();
          delete queryParams["page"];
          this.addQueryParamsToRouter(queryParams, buttonData, "search");

        } else if (buttonData["field"] === "Clear") {
          this.model().withSearchText("");
          buttonData["value"] = "";
          this.addQueryParams(buttonData, "search");

        } else if (buttonData["field"] === "Select") {
          this.$emit("withButtonPressed", buttonData);
          
        } else {
          this.$emit("withButtonPressed", buttonData);
        }
      },
      
      deleteDetails(query) {
        delete query.employeeListAction;
        delete query.employeeInviteAction;
        delete query.userId;
        return query;
      },

      reloadPage: function() {
        this.viewModel.withStateContentLoading();
        this.viewModel.clearAndBuildTableData();
        this.viewModel.loadEmployees();
      },

      paint: function() {
        this.redraw++;
      },

    },
  }
</script>