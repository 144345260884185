
import RcTableViewModel from "../../../../../shared/table/RcTableViewModel";

import ReportMap from "@/domain/model/report/ReportMap.js";
import ReportParams from "@/domain/model/report/ReportParams.js";
import ReportListFilter from "@/domain/model/report/ReportListFilter.js";

import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';
import ListViewUtils from "./ListViewUtils";

export default class ListViewModel extends RcTableViewModel {

  constructor(panel) {
    super(panel, true);
    this._companyId = ContentUtils.baseDecode(panel.$route.params.operatorId);
    this._userId = null;
    this.Strings = StringUtils;
    this.Contents = ContentUtils;
  }

  start() {
    super.start();
  }

  company() {
    return this.domain().companys().findById(this._companyId);
  }

  withToFrom(to, from) {
    super.withToFrom(to, from);
    this.log().info("View To: {0} From: {1}", [to.path, from.path]);
    if (to && from) {
      let toAction = to.query["action"];
      if (toAction === "addReport") {
        this.panel().showDialog = "addReportDialog";
        
      } else if (toAction === "runReport") {
        this.panel().selectedId = to.query["itemId"];
        this.panel().showDialog = "runReportDialog";

      } else if (toAction === "updateReport") {
        this.panel().selectedId = to.query["itemId"];
        this.panel().showDialog = "updateReportDialog";

      } else if (toAction === "detailsReport") {
        this.panel().selectedId = to.query["itemId"];
        this.panel().showDialog = "detailsReportDialog";

      } else if (toAction === "removeReport") {
        this.panel().selectedId = to.query["itemId"];
        this.panel().showDialog = "removeReportDialog";

      } else if (toAction === "restoreReport") {
        this.panel().selectedId = to.query["itemId"];
        this.panel().showDialog = "restoreReportDialog";

      } else {
        let refresh = false;

        var toQuery = Object.assign({}, to.query);
        var fromQuery = Object.assign({}, from.query);
        var action = fromQuery["action"];

        delete toQuery.action;
        delete toQuery.itemId;

        delete fromQuery.action;
        delete fromQuery.itemId;

        if (action == "addReport") {
          refresh = true;
        }
        
        if (JSON.stringify(toQuery, null, 0) !== JSON.stringify(fromQuery, null, 0)) {
          refresh = true;
        }

        if (StringUtils.isNotEmpty(this.panel().showDialog)) {
          this.panel().showDialog = ""; 
        } 

        if (refresh) {
          this.reloadPage();
        }
      }
    }
  }

  withEnterPressed(valueData) {
    super.withEnterPressed(valueData);
  }

  withValue(valueData) {
    super.withValue(valueData, "report");
    this.log().info("View Value {0}", [JSON.stringify(valueData, null, 2)]);
  }

  withButtonPressed(valueData) {
    super.withButtonPressed(valueData);
    this.log().info("Report Model Button {0}", [JSON.stringify(valueData, null, 2)]);

    if (valueData["field"] == "Add") {
      let query = this.urlQueryParams();
      query["action"] = "addReport";
      this.pushToRouter(query);

    } else if (valueData["field"] == "Update") {
      let query = this.urlQueryParams();
      query["action"] = "updateReport";
      query["itemId"] = this.Contents.baseEncode(valueData["id"]);
      this.pushToRouter(query);

    } else if (valueData["field"] == "Remove") {
      let query = this.urlQueryParams();
      query["action"] = "removeReport";
      query["itemId"] = this.Contents.baseEncode(valueData["id"]);
      this.pushToRouter(query);

    } else if (valueData["field"] == "Restore") {
      let query = this.urlQueryParams();
      query["action"] = "restoreReport";
      query["itemId"] = this.Contents.baseEncode(valueData["id"]);
      this.pushToRouter(query);

    } else if (valueData["field"] == "Run") {
      let query = this.urlQueryParams();
      query["action"] = "runReport";
      query["itemId"] = this.Contents.baseEncode(valueData["id"]);
      this.pushToRouter(query);

    } else if (valueData["field"] == "Details") {
      let query = this.urlQueryParams();
      query["action"] = "detailsReport";
      query["itemId"] = this.Contents.baseEncode(valueData["id"]);
      this.pushToRouter(query);

    } else if (valueData["field"] === "Download") {
      let host = this.domain.client().cdnHost;
      var url = ContentUtils.escape(valueData["object"]);
      url = url.replace("#", "%23");
      url = host + url;
      window.open(url, '_blank');
    
    } else if (valueData["field"] === "State") {
      this.addQueryParams(valueData, "state");
    }
  }

  buildRowData(tableData) {
    var utils = new ListViewUtils(this, tableData);
    utils.start();
    return this;
  }

  loadItems() {
    super.loadItems();
    var query = this.urlQueryParams();
    this.log().info("Query Params {0}", [JSON.stringify(query, null, 2)]);

    var context = {};
    context["model"] = this;
    context["query"] = query;
    context["pageName"] = "report";
    context["startTime"] = new Date();

    let filter = new ReportListFilter();
    filter
      .withState(query["state"] ? query["state"] : "active")
      .withPagination(this.pageSize(query, "report"), query["page"] ? (query["page"] - 1) : 0)
      .done();

    let search = query["search"] ? query["search"] : "";
    if (StringUtils.isNotEmpty(search)) {
      filter
        .withSearchText(search)
        .done();
      filter.put("company.name", search);
    }

    filter
      .withCompany(this._companyId)
      .done();
    
    let params = new ReportParams();
    params.withFilter(filter);

    var operation = this.domain().events().report().list(params);
    operation.send(this.loadedItemsListener, context);
  }

  loadedItemsListener(notification, context) {
    var model = context.model;
    context["loadTime"] = new Date();
    if (!super.loadedItemsListener(notification, context)) {
      return;
    }
    
    var payload = notification.payload();
    model.processPayload(payload, context);

    delete context.model;
    context["renderTime"] = new Date();
    model.log().info("Client Performance {0}", [JSON.stringify(context, null, 2)]);
  }

  processPayload(payload, context) {
    var model = context.model;
    var query = context.query;
    var map = new ReportMap(this.domain, payload.valuesFor(ReportMap.MODEL_NAME));

    model.setupData(payload, map)
    context["sortTime"] = new Date();

    model.setupPages(query, "report");

    model.showData();
  }

}