import RcTableViewUtils from "../../../../../shared/table/RcTableViewUtils";

import LabelDisplayRow from "@/portals/shared/cell//label/LabelDisplayRow";
import LabelValueDisplayRow from "@/portals/shared/cell//label/LabelValueDisplayRow";
import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";
import SpacerDisplayRow from "@/portals/shared/cell/spacer/SpacerDisplayRow";
import StringUpdateRow from "@/portals/shared/cell/string/StringUpdateRow.js";
import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow.js";

import ReportRow from "@/portals/shared/cell/report/ReportRow.js";

import MC from "@/domain/session/MC.js";
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

// import Company from "@/domain/model/company/Company.js";

export default class ListViewUtils extends RcTableViewUtils {

  static MONTHS() {
    return [
      { '@rid': 'all', 'name': 'All' },
    ];
  }

  constructor(model, tableData) {
    super(model, tableData);
    this.Mc = new MC();
    this.Contents = ContentUtils;
    this.Strings = new StringUtils();
  }

  start() {
    super.startV2("Report");
  }

  renderBody() {
    let row = null;
    let item = null;
    let even = true;
    let list = this.model().itemList();
    for (var index = 0; index < list.length; index++) {
      item = list[index];
      row = new ReportRow().withReport(item);
      row
        .withIsAdmin(this.model().isAdmin())
        .withEven(even);
      this.addBody(row);
      even = !even;
    }
  }

  renderHeader() {
    let query = this.model().panel().$route.query;
    let stateValue = query["state"];

    let buttonsRow = new RowDisplayRow()
      .withChild(new LabelDisplayRow().withLabel("Reports").withWidth(2))
      .withChild(new SpacerDisplayRow().withWidth(10))
      ;

    let addRow = null;
    if (this.model().isAdminDisplay()) {
      addRow = new SpacerDisplayRow().withWidth(2);
    } else {
      addRow = new ButtonDisplayRow().withWidth(2).withName("Add Report").withField("Add").withBackgroundPrimaryAction()
    }

    buttonsRow
      .withChild(addRow);

    buttonsRow
      .withChild(new ButtonDisplayRow().withWidth(1).withName("Refresh").withField("Refresh"));

    let stateRow = new RowDisplayRow()
      .withChild(new LabelDisplayRow().withWidth("1").withLabel("State"))
      .withChild(new ButtonDisplayRow().withWidth("1").withName("All").withKey("All").withField("State").withDefaultValue("all").withSelected(stateValue === "all"))
      .withChild(new ButtonDisplayRow().withWidth("1").withName("Active").withKey("Active").withField("State").withDefaultValue("active").withSelected(stateValue === "active" || !stateValue))
      .withChild(new ButtonDisplayRow().withWidth("1").withName("Removed").withKey("Removed").withField("State").withDefaultValue("inactive").withSelected(stateValue === "inactive"))
      ;

    let searchRow = new RowDisplayRow()
      .withChild(new LabelValueDisplayRow().withWidth("1").withLabel("Search"))
      .withChild(new StringUpdateRow().withWidth("5")
        .withShowLabel(false).withField("Search")
        .withKey("Search").withName("Search")
        .withDefaultValue(query["search"] ? query["search"] : ""))
      .withChild(new ButtonDisplayRow().withWidth("1").withName("Go").withKey("Search").withField("Search").withSelected(false))
      .withChild(new ButtonDisplayRow().withWidth("1").withName("Clear").withKey("Search").withField("Clear").withSelected(false))
      .withChild(new SpacerDisplayRow().withWidth("7"));

    let filterRow = new BoxDisplayRow().withBorder()
      .withChild(new LabelDisplayRow().withLabel("Filters"))
      .withChild(stateRow)
      .withChild(searchRow)
      ;

    let pageRow = this.setupHeaderPagesRow();

    this.addHeader(new BoxDisplayRow()
      .withChild(buttonsRow)
      .withChild(filterRow)
      .withChild(pageRow)
    );
    let titleRow = new RowDisplayRow().withBackgroundTitle();
    titleRow.withChild(new TitleDisplayRow().withWidth(15).withTitle("Details"))

    this.addHeader(titleRow);
  }

}