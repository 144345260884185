<template>
    <Row>
        <Column :width="15">
            <div id="detailsModal" class="rc-modal-dialog">
            <!-- Modal content -->
            <div class="rc-modal-content-dialog-wide-tall">
                <div class="rc-modal-header-dialog">
                <Box :key="redraw">
                    <WorkingDisplayRow v-if="this.showDialog == 'workingDialog'"></WorkingDisplayRow>
                    <UpdateRow v-if="this.showDialog == 'updateDialog'" :vehicleId="itemId" v-on:withValue="withFieldValue" v-on:withButtonPressed="withButtonPressed"></UpdateRow>
                    <Row 
                        v-for="(row, rowIndex) in viewHeaderData" 
                        :key="rowIndex" >
                        <CellFactory 
                            :rowData="row.data"
                            :showMissingRequirements="showMissingRequirements"
                            v-on:withValue="withFieldValue"
                            v-on:withButtonPressed="withButtonPressed"
                            />
                    </Row>
                </Box>
            </div>
        <div class="rc-modal-body-dialog">
            <div class="rc-modal-body-inner-dialog rc-border-thin">
            <Box v-if="this.tab === 'details'" :key="redraw + 1">
                <Row 
                v-for="(row, rowIndex) in viewBodyData" 
                :key="rowIndex" >
                    <CellFactory 
                    :rowData="row.data"
                    :showMissingRequirements="showMissingRequirements"
                    v-on:withValue="withFieldValue"
                    v-on:withButtonPressed="withButtonPressed"
                    />
                </Row>
            </Box>
            <Box v-else-if="this.tab === 'inspection'">
                <InspectionListView 
                :userId="itemId"
                v-on:withValue="withFieldValue"
                v-on:withButtonPressed="withButtonPressed">
                </InspectionListView>
            </Box>
            <Box v-else>
                <HoursListView 
                :userId="itemId"
                v-on:withValue="withFieldValue"
                v-on:withButtonPressed="withButtonPressed">
                </HoursListView>
            </Box>
            </div>
        </div>
        <div class="rc-modal-footer-dialog">
            <Box :key="redraw + 2">
                <Row 
                    v-for="(row, rowIndex) in viewFooterData" 
                    :key="rowIndex" >
                    <CellFactory 
                        :rowData="row.data"
                        :showMissingRequirements="showMissingRequirements"
                        v-on:withValue="withFieldValue"
                        v-on:withButtonPressed="withButtonPressed"
                        />
                </Row>
            </Box>
        </div>
        </div>
        </div>
        </Column>
    </Row>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';

import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

import MC from "@/domain/session/MC.js";

import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Box from "@/portals/shared/library/box/Box.vue";
import CellFactory from "@/portals/shared/cell/CellFactory.vue";

import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
import ErrorDisplayRow from "@/portals/shared/cell/error/ErrorDisplayRow";
import SpacerDisplayRow from "@/portals/shared/cell/spacer/SpacerDisplayRow";

import LabelDisplayRow from "@/portals/shared/cell//label/LabelDisplayRow";

import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";

import LabelValueDisplayRow from "@/portals/shared/cell/label/LabelValueDisplayRow";
import WorkingDisplayRow from '@/portals/shared/views/working/WorkingDisplayRow.vue';
import InspectionListView from '@/portals/customer/operator/views/inspection/list/ListView.vue';
import HoursListView from '@/portals/customer/operator/views/timesheet/list/ListView.vue';

import User from '@/domain/model/user/User.js';
import UpdateRow from '../update/UpdateRow.vue';

export default {
    name: 'employee-list-display-view',
    components: {
        Column,
        Row,
        Box,
        CellFactory,
        WorkingDisplayRow,
        InspectionListView,
        HoursListView,
        UpdateRow
    },
    props: {
        itemId: { type: String, default: "" },
    },
    data() {
        return {
            redraw: 1,

            MC: new MC(),
            Strings: StringUtils,
            Contents: ContentUtils,
            Consts: ConstUtils,

            user: null,
            employee: null,
            company: null,

            tab: 'details',

            showMissingRequirements: false,
            viewBodyData: [],
            viewHeaderData: [],
            viewFooterData: [],

            kvps: {},
            error: null,
            showDialog: null,
        }
    },
    computed: {
    ...mapGetters([
                   'auth_connected',
                   'auth_socket_status',
                   'signin_event',
                   'auth_client',
                   'domain',
                   'VehicleStorage_lastEvent',
                   ]),
    },
    watch: {
        auth_connected() {
        ConnectionUtils.sendSignInEvent(this);
        },
        auth_socket_status() {
        ConnectionUtils.displayStatus(this);
        },
        signin_event() {
        ConnectionUtils.createDomainSession(this);
        },
        EmployeeStorage_lastEvent() {
            let user = this.domain.users().findById(ContentUtils.baseDecode(this.itemId));
            let employee = this.domain.employees().findById(ContentUtils.baseDecode(this.employee.id()));
                if (this.user.modifiedDate() < user.modifiedDate()) {
                    this.user = user;
                    this.employee = employee;
                    this.company = this.employee.company().find();
                    this.paint();
                }
                if (this.employee.modifiedDate() < employee.modifiedDate()) {
                    this.user = user;
                    this.employee = employee;
                    this.company = this.employee.company().find();
                    this.paint();
                }
        },
        $route (to, from){
            if (to && from) {
                if (to.query["employeeListAction"] === "userDetails") {
                    this.tab = 'details';
                    this.paint();
                    this.mainScreen();
                }
                if (to.query["employeeListAction"] === "userInspections") {
                    this.tab = 'inspection';
                    this.paint();
                    this.mainScreen();
                }
                if (to.query["employeeListAction"] === "userHours") {
                    this.tab = 'hours';
                    this.paint();
                    this.mainScreen();
                }
                if (to.query["employeeDetailsAction"] === "update") {
                    this.showDialog = "updateDialog";
                    this.paint();
                }
                if (to.query["employeeDetailsAction"] != "update") {
                    this.showDialog = null;
                    this.paint();
                }
            }
        },
    },
    mounted: function () {
        ConnectionUtils.ensureConnection(this);
    },

    methods: {
        ...mapActions([
                    'AuthStorage_updateCompany',
                    'addListenerAction',
                    'removeListenerAction',
                    'updateDomain',
                    ]),
        start: function() {
            this.user = new User(this.domain);
            if (StringUtils.isNotEmpty(this.itemId)) {
                let companyId = ContentUtils.baseDecode(this.$route.params.operatorId);
                this.user = this.domain.users().findById(ContentUtils.baseDecode(this.itemId));
                this.company = this.domain.companys().findById(companyId);
                let employeeMap = this.domain.employees().findByUserId(this.user.id());
                this.employee = employeeMap.findByCompanyId(companyId);
            }
            this.tab = this.getAction();
            this.setup();
            this.mainScreen();
        },

        setup: function() {
            // window.onclick = this.onClick;
        },

        mainScreen() {
            this.viewHeaderData = [];
            this.viewBodyData = [];
            this.viewFooterData = [];

            let titleString = "Details";
            if (this.tab === 'inspection') { titleString = "Inspections"; }
            if (this.tab === 'hours') { titleString = "Hours of Service"; }

            let formatted = StringUtils.format("{1} - {0}", [this.user.fullName(), titleString]);
            let detailsButton = new ButtonDisplayRow()
                .withWidth(3)
                .withName("Details")
                .withField("EmployeeDetailsSelect")
                .withSelected(this.tab === 'details')
            let inspectionButton = new ButtonDisplayRow()
                .withWidth(3).withName("Inspections")
                .withField("InspectionsSelect")
                .withSelected(this.tab === 'inspection')
            let hoursButton = new ButtonDisplayRow()
                .withWidth(3).withName("Hours of Service")
                .withField("HoursSelect")
                .withSelected(this.tab === 'hours')
            let spacer = new SpacerDisplayRow().withWidth(6);

            let tabRow = new RowDisplayRow()

            tabRow.withChild(spacer);
            if (!this.company.find().hasTimesheets()) {
                tabRow.withChild(new SpacerDisplayRow().withWidth(3));
            }
            tabRow.withChild(detailsButton);
            tabRow.withChild(inspectionButton);
            if (this.company.find().hasTimesheets()) {
                tabRow.withChild(hoursButton);
            }

            this.viewHeaderData.push(tabRow);
            this.viewHeaderData.push(new TitleDisplayRow().withTitle(formatted));

            this.viewFooterData.push(new RowDisplayRow().withWidth(15).withBorder().withChildren([
                new ButtonDisplayRow().withWidth(2).withName("Close").withField("EmployeeDetailsClose"),
                new SpacerDisplayRow().withWidth(11),
                new ButtonDisplayRow().withWidth(2).withName("Update").withField("EmployeeDetailsUpdate"),
            ]));

            let user = this.user;
            let employee = this.employee;

            var errorBox = new BoxDisplayRow().withBorder(true)
                          .withChild(new RowDisplayRow()
                            .withChild(new SpacerDisplayRow().withWidth("1"))
                            .withChild(new ErrorDisplayRow().withWidth("13").withError(this.error))
                            .withChild(new SpacerDisplayRow().withWidth("1"))
                          );
            if (this.error) {
                this.viewBodyData.push(errorBox);
            }

            let roleId = employee.roles().find().first().id();
            let role = ContentUtils.ROLES().find(dict => dict['@rid'] === roleId);

            let userDetails = new RowDisplayRow().withChildren([
                new BoxDisplayRow().withBorder(true).withWidth(8).withChildren([
                    new TitleDisplayRow().withTitle("User Details").withSubtitle(true),
                    new LabelValueDisplayRow().withLabel("First Name").withValue(user.first()),
                    new LabelValueDisplayRow().withLabel("Last Name").withValue(user.last()),
                    new LabelValueDisplayRow().withLabel("Email").withValue(user.email()),
                    new LabelValueDisplayRow().withLabel("Phone No.").withValue(user.phone()),
                ]),
                new BoxDisplayRow().withBorder(true).withWidth(7).withChildren([
                    new TitleDisplayRow().withTitle("Role and Employee ID").withSubtitle(true),
                    new LabelDisplayRow().withLabel("Every user has a role, a manager or and owner have the most permissions, they can perform inspections as well as use this portal"),
                    new LabelValueDisplayRow().withLabel("Role").withValue(role["name"]),
                    new LabelValueDisplayRow().withLabel("Employee ID").withValue(employee.employmentId()),
                ]),
            ]);

            let moreInfoRow = new RowDisplayRow().withChildren([
                new BoxDisplayRow().withBorder(true).withWidth(8).withChildren([
                    new TitleDisplayRow().withTitle("Address Information").withSubtitle(true),
                    new LabelDisplayRow().withLabel("If the user takes the vehicle home at night, this provides the user with an easy to use button in the app to set their address when completing inspections"),
                    new LabelValueDisplayRow().withLabel("Street").withValue(user.street()),
                    new LabelValueDisplayRow().withLabel("City").withValue(user.city()),
                    new LabelValueDisplayRow().withLabel("Jurisdiction").withValue(user.jurisdiction()),
                    new LabelValueDisplayRow().withLabel("Country").withValue(user.country()),
                    new LabelValueDisplayRow().withLabel("Postal Code").withValue(user.postal()),
                ]),
                new BoxDisplayRow().withWidth(7).withChildren([
                    new BoxDisplayRow().withBorder(true).withChildren([
                        new TitleDisplayRow().withTitle("Driver's License Information").withSubtitle(true),
                        new LabelDisplayRow().withLabel("With this information the portal will show you a message when the license is about to expire"),
                        new LabelValueDisplayRow().withLabel("Class").withValue(user.licenseClass()),
                        new LabelValueDisplayRow().withLabel("Expiry Date").withValue(user.licenseExpiryDateDisplay()),
                    ]),
                    new SpacerDisplayRow(),
                    new BoxDisplayRow().withBorder(true).withChildren([
                        new TitleDisplayRow().withTitle("Employment Start and End Information").withSubtitle(true),
                        new LabelDisplayRow().withLabel("When did this person start and stop working for your company."),
                        new LabelValueDisplayRow().withLabel("Start Date").withValue(ContentUtils.decodeDate(employee.startDate())),
                        new LabelValueDisplayRow().withLabel("End Date").withValue(ContentUtils.decodeDate(employee.endDate())),
                    ]),
                ]),
            ]);

            this.viewBodyData.push(userDetails);
            this.viewBodyData.push(moreInfoRow);
            this.paint();
        },

        paint() {
            this.redraw++;
        },

        onClick: function(event) {
            var modal = document.getElementById("detailsModal");
            if (event.target === modal) {
                this.goBack();
            }
        },

        goBack: function() {
            this.$router.back();
        },

        log() {
            return this.domain.logger();
        },

        resetMissingValues() {
            if (this.showMissingRequirements) {
                this.showMissingRequirements = false;
            }
        },

        callback: function(msg) {
            if (msg) {
                //
            }
        },

        areRequirementsMet() {
            var met = true;
            return met;
        },

        withFieldValue: function(valueData) {
            this.$emit("withValue", valueData);
        },

        withButtonPressed: function(buttonData) {
            if (!buttonData) {
                return;
            }
            if (buttonData.field === "EmployeeDetailsClose") {
                let query = this.urlQueryParams();
                delete query.status;
                delete query.time;
                this.$router.push({ query });
                buttonData.field = "DetailsClose";
                this.$emit("withButtonPressed", buttonData);
            }
            if (buttonData.field === "EmployeeDetailsUpdate") {
                let query = this.urlQueryParams();
                query["employeeDetailsAction"] = "update";
                this.$router.push({ query });
                this.showDialog = "updateDialog";
                this.$emit("withButtonPressed", buttonData);
            }
            if (buttonData.field === "Close") {
                let query = this.urlQueryParams();
                delete query["employeeDetailsAction"];
                this.$router.push({ query });
                this.showDialog = null;
            }

            if (buttonData.field === "EmployeeDetailsSelect") {
                let query = this.urlQueryParams();
                query["employeeListAction"] = "userDetails";
                delete query.status;
                delete query.time;
                this.$router.push({ query });
            }
            if (buttonData.field === "InspectionsSelect") {
                let query = this.urlQueryParams();
                query["employeeListAction"] = "userInspections";
                delete query.status;
                delete query.time;
                this.$router.push({ query });
            }
            if (buttonData.field === "HoursSelect") {
                let query = this.urlQueryParams();
                query["employeeListAction"] = "userHours";
                delete query.status;
                delete query.time;
                this.$router.push({ query });
            }
        },

        getAction() {
            var query = this.urlQueryParams();
            let action = query['employeeListAction'];
            let value = ''
            if (action === 'userDetails') {
                value = 'details';
            }
            if (action === 'userInspections') {
                value = 'inspection';
            }
            if (action === 'userHours') {
                value = 'hours';
            }
            return value;
        },

        urlQueryParams() {
            return Object.assign({}, this.$route.query);
        },

    },

}

</script>