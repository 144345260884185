<template>
    <AddView 
        :itemId="itemId" 
        @click="withButtonPressed" 
        @withButtonPressed="withButtonPressed" 
        @withValue="withValue">
    </AddView>
  </template>
  
  <script>
  import AddView from './DisplayView.vue';
  export default {
    name: 'employee-list-display-row',
    components: {
      AddView
    },
    props: {
      itemId: { type: String, default: "" },
    },
    methods: {
      withValue: function(event) {
        this.$emit('withValue', event);
      },
      withButtonPressed: function(event) {
        this.$emit('withButtonPressed', event);
      },
    }
  }
  </script>
  