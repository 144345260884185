<template>
  <Row>
    <Column :width="15" :key="redraw">
      <div id="detailsModal" class="rc-modal-dialog">
        <!-- Modal content -->
        <div class="rc-modal-content-dialog-wide">

          <div class="rc-modal-header-dialog">
            <Box >
              <UsersRow v-if="this.showDialog == 'userListDialog'"
                v-on:withValue="withFieldValue"
                v-on:withButtonPressed="withButtonPressed"
                :embedded="true"
                ></UsersRow>
              <VehiclesRow v-if="this.showDialog == 'vehicleListDialog'"
                v-on:withValue="withFieldValue"
                v-on:withButtonPressed="withButtonPressed"
                :embedded="true"
                ></VehiclesRow>
              <Row 
                v-for="(row, rowIndex) in viewHeaderData" 
                :key="rowIndex" >
                  <CellFactory 
                    :rowData="row.data"
                    :showMissingRequirements="showMissingRequirements"
                    v-on:withValue="withFieldValue"
                    v-on:withButtonPressed="withButtonPressed"
                    />
              </Row>
            </Box>
          </div>

          <div class="rc-modal-body-dialog">
            <div class="rc-modal-body-inner-dialog rc-border-thin">
              <Box :key="redrawMain">
                <Row 
                  v-for="(row, rowIndex) in viewBodyData" 
                  :key="rowIndex" >
                    <CellFactory 
                      :rowData="row.data"
                      :showMissingRequirements="showMissingRequirements"
                      v-on:withValue="withFieldValue"
                      v-on:withButtonPressed="withButtonPressed"
                      />
                </Row>
              </Box>
            </div>
          </div>

          <div class="rc-modal-footer-dialog">
            <Box>
              <Row 
                v-for="(row, rowIndex) in viewFooterData" 
                :key="rowIndex" >
                  <CellFactory 
                    :rowData="row.data"
                    :showMissingRequirements="showMissingRequirements"
                    v-on:withValue="withFieldValue"
                    v-on:withButtonPressed="withButtonPressed"
                    />
              </Row>
            </Box>
          </div>

        </div>
      </div>
    </Column>
  </Row>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';

import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

import MC from "@/domain/session/MC.js";

import CellFactory from "@/portals/shared/cell/CellFactory.vue";
import Box from "@/portals/shared/library/box/Box.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";

import LabelValueDisplayRow from "@/portals/shared/cell/label/LabelValueDisplayRow";
import ValueDisplayRow from "@/portals/shared/cell/value/ValueDisplayRow";
import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
import ErrorDisplayRow from "@/portals/shared/cell/error/ErrorDisplayRow";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";
import SpacerDisplayRow from '@/portals/shared/cell/spacer/SpacerDisplayRow';
import StringDisplayRow from "@/portals/shared/cell/string/StringDisplayRow";
import StringUpdateRow from "@/portals/shared/cell/string/StringUpdateRow";
import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";
import TextAreaUpdateRow from '@/portals/shared/cell/textarea/TextAreaUpdateRow';
import DateUpdateRow from "@/portals/shared/cell/date/DateUpdateRow";
// import LabelDisplayRow from "@/portals/shared/cell/label/LabelDisplayRow";

import UsersRow from './users/UsersRow.vue';
import VehiclesRow from './vehicles/VehiclesRow.vue';

import Report from '@/domain/model/report/Report.js';
import ReportMap from '@/domain/model/report/ReportMap.js';
import ReportParams from '@/domain/model/report/ReportParams.js';

import UserMap from "@/domain/model/user/UserMap.js";
import VehicleMap from "@/domain/model/vehicle/VehicleMap.js";

export default {
  name: "report-list-add-view",
  components: {
    Column, Row, 
    Box,
    CellFactory,
    UsersRow,
    VehiclesRow,
  },
  props: {
    itemId: { type: String, default: "" },
  },
  data() {
    return {
      redraw: 1,
      redrawMain: 10000,

      MC: new MC(),
      Strings: StringUtils,
      Contents: ContentUtils,
      Consts: ConstUtils,

      showMissingRequirements: false,
      viewHeaderData: [],
      viewBodyData: [],
      viewFooterData: [],
      report: null,
      kvps: {},
      error: null,

      users: null,
      userList: [],

      vehicles: null,
      vehicleList: [],

      showDialog: null,
      StringUpdateRow: StringUpdateRow
    };
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'auth_socket_status',
                   'signin_event',
                   'auth_client',
                   'domain',
                   ]),
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   'AuthStorage_updateCompany',
                   ]),

    start: function() {
      this.report = new Report(this.domain);
      if (StringUtils.isNotEmpty(this.itemId)) {
        var id = ContentUtils.baseDecode(this.itemId);
        this.report = this.domain.reports().findById(id);
        
        this.users = this.report.users().find();
        this.userList = this.users.sortByName();

        this.vehicles = this.report.vehicles().find();
        this.vehicleList = this.vehicles.sortByName();
      }
      this.setup();
      this.mainScreen();
    },

    setup: function() {
      //window.onclick = this.onClick;
    },

    mainScreen() {
      this.viewHeaderData = [];
      this.viewBodyData = [];
      this.viewFooterData = [];

      if (this.report.isNew()) {
        this.viewHeaderData.push(new TitleDisplayRow().withTitle(StringUtils.format("Create a new Report")));
      } else {
        this.viewHeaderData.push(new TitleDisplayRow().withTitle(StringUtils.format("Update Report: {0}", [this.report.name()])));
      }

      var errorBox = new BoxDisplayRow().withBorder(true)
                          .withChild(new RowDisplayRow()
                            .withChild(new SpacerDisplayRow().withWidth("1"))
                            .withChild(new ErrorDisplayRow().withWidth("13").withError(this.error))
                            .withChild(new SpacerDisplayRow().withWidth("1"))
                          );
      if (this.error) {
        this.viewBodyData.push(errorBox);
      }
      let usersRow = new BoxDisplayRow().withBorder();
      for (let index = 0; index < this.userList.length; index++) {
        let user = this.userList[index];
        let userRow = new RowDisplayRow();
          userRow
            .withChild(new ValueDisplayRow().withWidth(13).withValue((index + 1) + " " + user.fullName()))
            .withChild(new ButtonDisplayRow().withWidth(2).withName("Remove").withId(user.id()).withField("Remove").withObject("User"));
        usersRow.addChild(userRow);
      }
      if (this.userList.length == 0) {
        let userRow = new RowDisplayRow();
          userRow
            .withChild(new StringDisplayRow().withWidth(15).withValue("Select Users if you would like to see inspections grouped by users"))
        usersRow.addChild(userRow);
      }
      usersRow.addChild(new SpacerDisplayRow());

      let vehiclesRow = new BoxDisplayRow().withBorder();
      for (let index = 0; index < this.vehicleList.length; index++) {
        let vehicle = this.vehicleList[index];
        let vehicleRow = new RowDisplayRow();
          vehicleRow
            .withChild(new ValueDisplayRow().withWidth(13).withValue((index + 1) + ". " + vehicle.name() + " " + vehicle.plate()))
            .withChild(new ButtonDisplayRow().withWidth(2).withName("Remove").withId(vehicle.id()).withField("Remove").withObject("Vehicle"));
        vehiclesRow.addChild(vehicleRow);
      }
      if (this.vehicleList.length == 0) {
        let vehicleRow = new RowDisplayRow();
        vehicleRow
            .withChild(new StringDisplayRow().withWidth(15).withValue("Select Vehicles if you would like to see inspections grouped by vehicles"))
        vehiclesRow.addChild(vehicleRow);
      }
      vehiclesRow.addChild(new SpacerDisplayRow());

      let bodyRow = new BoxDisplayRow()
                          .withWidth("11")
                          .withChild(new LabelValueDisplayRow().withLabelWidth(2).withLabel("Template Name").withValue("Inspection and Hours of Service export to zip file").withKey("name").withField("field").withObject("Report"))
                          .withChild(new ValueDisplayRow().withFontSize("medium").withValue("This will find all of the Inspections and Hours of service for the users and vehicles identified below between the two dates. A zip file will be created containing PDF files for all inspections and hours of service completed during this time. The files can be downloaded and shared with anyone who has requested them."))
                          .withChild(new StringUpdateRow().withName("Name").withDefaultValue(this.report.name()).withKey("field").withField("name").withObject("Report").withMandatoryValue(true).withHint("A name to identify this report, for example Jun to Sept 2024 Facility Audit"))
                          .withChild(new TextAreaUpdateRow().withName("Description").withDefaultValue(this.report.description()).withKey("field").withField("description").withObject("Report")
                                          .withRows(2).withHint("A description of what this report will do for you, for example Facility Audit for June 1st to Aug 31st 2024 as request by MTO."))
                          .withChild(new SpacerDisplayRow())
                          .withChild(new BoxDisplayRow().withBorder()
                            .withChild(new TitleDisplayRow().withTitle("Parameters").withSubtitle(true))
                            .withChild(new RowDisplayRow()
                                .withChild(new DateUpdateRow().withWidth(5).withName("Start Time").withMandatoryValue(true).withField("startTime").withDefaultValue(this.report.startTime() + ""))
                                .withChild(new DateUpdateRow().withWidth(5).withName("End Time").withMandatoryValue(true).withField("endTime").withDefaultValue(this.report.endTime() + ""))
                            )
                            .withChild(new BoxDisplayRow().withBorder()
                              .withChild(new TitleDisplayRow().withTitle("Users").withSubtitle(true))
                              .withChild(new RowDisplayRow()
                                .withChild(new SpacerDisplayRow().withWidth(13))
                                .withChild(new ButtonDisplayRow().withWidth(2).withName("+ User").withField("AddUser").withBackgroundPrimaryAction())
                              )
                              .withChild(usersRow)
                            )
                            .withChild(new BoxDisplayRow().withBorder()
                              .withChild(new TitleDisplayRow().withTitle("Vehicles").withSubtitle(true))
                              .withChild(new RowDisplayRow()
                                .withChild(new SpacerDisplayRow().withWidth(13))
                                .withChild(new ButtonDisplayRow().withWidth(2).withName("+ Vehicle").withField("AddVehicle").withBackgroundPrimaryAction())
                              )
                              .withChild(vehiclesRow)
                            )
                          )
      this.viewBodyData.push(bodyRow);

      let footerRow = new BoxDisplayRow()
                  .withBorder(true)
                  .withChild(
                    new RowDisplayRow()
                      .withChild(new ButtonDisplayRow().withWidth("2").withName("Cancel").withField("DetailsClose"))
                      .withChild(new StringDisplayRow().withWidth("8"))
                      .withChild(new ButtonDisplayRow().withWidth("3").withName("Save And Run").withField("SendAndRun").withBackgroundPrimaryAction())
                      .withChild(new ButtonDisplayRow().withWidth("2").withName("Save").withField("Send").withBackgroundPrimaryAction())
                  );
      this.viewFooterData.push(footerRow);
    },

    sendingScreen() {
      this.viewBodyData = [];
      this.viewFooterData = [];
      this.viewHeaderData = [];

      let bodyBox = new BoxDisplayRow().withChild(new RowDisplayRow()
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                              .withChild(new BoxDisplayRow()
                                    .withWidth("11")
                                    .withChild(new StringDisplayRow()
                                                  .withValue("Sending"))
                                  )
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                            );
      this.viewBodyData.push(bodyBox);
      this.paint();
    },

    doneScreen() {
      this.viewHeaderData = [];
      this.viewBodyData = [];
      this.viewFooterData = [];

      let bodyBox = new BoxDisplayRow().withChild(new RowDisplayRow()
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                              .withChild(new BoxDisplayRow()
                                    .withWidth("11")
                                    .withChild(new StringDisplayRow()
                                                  .withMandatoryValue(true)
                                                  .withValue("Done!"))
                                  )
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                            );

      this.viewBodyData.push(bodyBox);
      this.paint();
    },

    paint: function() {
      this.redraw++;
    },

    paintMain: function() {
      this.redrawMain++;
    },

    onClick: function(event) {
      var modal = document.getElementById("detailsModal");
      if (event.target == modal) {
        this.goBack();
      }
    },
    
    log() {
      return this.domain.logger();
    },

    resetMissingValues() {
      if (this.showMissingRequirements) {
        this.showMissingRequirements = false;
      }
    },

    withFieldValue: function(valueData) {
      this.resetMissingValues();
      this.report.with(valueData);
      this.log().info("Dialog Value {0}", [JSON.stringify(this.report.data, null, 2)]);
    },
    
    withButtonPressed: function(buttonData) {
      this.resetMissingValues();

      this.log().info("Dialog Button {0}", [JSON.stringify(buttonData, null, 2)]);
      if (buttonData["field"] === "DetailsClose") {
        this.$emit("withButtonPressed", buttonData);

      } else if (buttonData["field"] === "AddUser") {
        this.showDialog = "userListDialog";

      } else if (buttonData["field"] === "AddVehicle") {
        this.showDialog = "vehicleListDialog";

      } else if (buttonData["field"] === "Send") {
        this.doSendAction(false);

      } else if (buttonData["field"] === "SendAndRun") {
        this.doSendAction(true);

      } else if (buttonData["field"] === "PopupClose") {
        this.showDialog = null;

      } else if (buttonData["field"] === "Remove") {
        if (buttonData["object"] == "User") {
          this.users.remove(buttonData["id"]);
          this.userList = this.users.sortByName();
          this.report.withUsers(this.users);
        }
        if (buttonData["object"] == "Vehicle") {
          this.vehicles.remove(buttonData["id"]);
          this.vehicleList = this.vehicles.sortByName();
          this.report.withVehicles(this.vehicles);
        }
        this.mainScreen();
        this.paintMain();

      } else if (buttonData["field"] === "PopupDone") {
        if (buttonData["object"] == "User") {
          let resultMap = new UserMap(this.domain, buttonData["users"]); 
          if (this.users == null) {
            this.users = resultMap;
          } else {
            this.users.addAll(resultMap);
          }
          this.userList = this.users.sortByName();
          this.report.withUsers(this.users);
        }
        if (buttonData["object"] == "Vehicle") {
          let resultMap = new VehicleMap(this.domain, buttonData["vehicles"]); 
          if (this.vehicles == null) {
            this.vehicles = resultMap;
          } else {
            this.vehicles.addAll(resultMap);
          }
          this.vehicleList = this.vehicles.sortByName();
          this.report.withVehicles(this.vehicles);
        }
        this.showDialog = null;
        this.mainScreen();
        this.paintMain();
      } else {
        //this.$emit("withButtonPressed", buttonData)
      }
    },

    doSendAction(runReport) {
      this.resetMissingValues();
      if (!this.areRequirementsMet()) {
        this.showMissingRequirements = true;
        return;
      }
      this.sendingScreen();
      let session = this.domain.session();
      let map = new ReportMap(this.domain);
      this.report.setTimeZone(this.domain);
      this.report.withTemplateName("FacilityAudit");
      if (this.report.isNew()) {
        this.report.withCompany(session.company());
      }

      map.addReport(this.report);
      
      if (runReport) {
        this.report.withStateReadyToRun();
      }

      let params = new ReportParams();
      params.put(map.modelName(), map);

      let context = {};
      context["view"] = this;

      let operation = this.domain.events().report().save(params);
      operation.send(this.doneSendAction, context)
    },

    doneSendAction(notification, context) {
      this.doneScreen();
      if (!notification || !context) {
        return;
      }
      var buttonData = {};
      buttonData["field"] = "Close";
      buttonData["refresh"] = "true";
      this.$emit("withButtonPressed", buttonData);
    },

    displayProgress: function(msg) {
      this.callback(msg);
    },

    callback: function(msg) {
      if (msg) {
        //
      }
    },

    goBack: function() {
      this.$router.back();
    },

    areRequirementsMet: function() {
      var met = true;
      
      if (met && StringUtils.isEmpty(this.report.name())) {
        met = false;
      }

      if (met && StringUtils.isEmpty(this.report.startTime())) {
        met = false;
      }

      if (met && StringUtils.isEmpty(this.report.endTime())) {
        met = false;
      }

      return met;
    },

    saveReport() {
      //
    },

    saveAndRunReport() {
      //
    },

    savedReportListener() {
      //
    },

  },
}
</script>