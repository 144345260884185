import RcTableViewUtils from "../../../../../shared/table/RcTableViewUtils";

import LabelDisplayRow from "@/portals/shared/cell//label/LabelDisplayRow";
import LabelValueDisplayRow from "@/portals/shared/cell//label/LabelValueDisplayRow";
import StringDisplayRow from "@/portals/shared/cell//string/StringDisplayRow";
import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";
import SpacerDisplayRow from "@/portals/shared/cell/spacer/SpacerDisplayRow";
import StringListUpdateRow from "@/portals/shared/cell/string/list/StringListUpdateRow.js";
import StringUpdateRow from "@/portals/shared/cell/string/StringUpdateRow.js";
import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";

import MC from "@/domain/session/MC.js";
import ContentUtils from '@/utils/ContentUtils.js';

import EmployeeRow from '@/portals/shared/cell/employee/EmployeeRow.js';

export default class ListViewUtils extends RcTableViewUtils {

  constructor(model, tableData) {
    super(model, tableData);
    this.MC = new MC();
    this.pages = [];
  }

  start() {
    if (this.model().isStatePageLoading()) {
      super.start();
      return;
    }

    this.renderHeader(this.model().totalItems());

    if (this.model().isStateContentLoading()) {
      super.start();
      return;
    }

    if (this.model().isStateContentLoaded()) {
      this.renderBody();
    }

    this.renderFooter(this.model().totalItems());
  }

  renderBody() {
    let query = this.model().panel().$route.query;
    let row = null;
    let even = true;
    let item = null;
    let list = this.model().users();
    for (var index = 0; index < list.length; index++) {
      item = list[index];
      let employee = item.employee();
      row = new EmployeeRow().withEmployee(employee, item);
      row
        .withIsAdmin(this.model().isAdmin())
        .withIsSelect(this.model().panel().usedFor == "selection")
        .withId(item.id())
        .withIndex(index + ((this.model().pageNumber(query) - 1) * this.model().pageSize(query, "employee")))
        .withEven(even);
      this.addBody(row);
      even = !even;
    }
  }

  renderFooter(totalItems) {
    let query = this.model().panel().$route.query;
    this.pages = this.model().pages();
    this.addFooter(
      new BoxDisplayRow()
        .withWidth("15")
        .withChild(new RowDisplayRow()
          .withChild(new BoxDisplayRow()
            .withWidth("15")
            .withChild(new RowDisplayRow()
              .withChild(new LabelValueDisplayRow().withWidth("4").withLabelWidth("5").withLabel("Showing").withValue(this.createPageInfo(totalItems, query, "employee")))
              .withChild(new StringDisplayRow().withWidth("7"))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("<").withField("PreviousPage"))
              .withChild(new StringListUpdateRow().withWidth("2")
                .withShowLabel(false)
                .withName("Jump to Page")
                .withField("PageIndex")
                .withDefaultValue(this.model().pageNumber(query))
                .withOptions(this.pages))
              .withChild(new ButtonDisplayRow().withWidth("1").withName(">").withField("NextPage"))
            )
          )
        )
    );
  }

  renderHeader(totalItems) {
    let query = this.model().panel().$route.query;
    let buttons = new RowDisplayRow()
      .withChild(new LabelDisplayRow().withWidth(2).withLabel("Users"))
      .withChild(new SpacerDisplayRow().withWidth(8));

    if (this.model().company().hasSso()) {
      buttons
        .withChild(new SpacerDisplayRow().withWidth(2));
      if (this.model().isAdmin() && !this.model().isAdminDisplay()) {
        buttons
              .withChild(new ButtonDisplayRow()
                .withWidth("2").withName("Add User").withField("Add").withBackgroundPrimaryAction());
      } else {
        if (this.model().isAdmin()) {
          buttons
            .withChild(new SpacerDisplayRow().withWidth(2));
        } else {
          if (this.model().panel().usedFor == "selection") {
            buttons.withChild(new SpacerDisplayRow().withWidth(2));
          } else {
            buttons
              .withChild(new ButtonDisplayRow()
                .withWidth("2").withName("Add User").withField("Add").withBackgroundPrimaryAction());
          }
        }
      }
    } else {
      buttons
        .withChild(new SpacerDisplayRow().withWidth(2));
      if (this.model().isAdmin() && !this.model().isAdminDisplay()) {
        buttons
            .withChild(new ButtonDisplayRow()
              .withWidth("2").withName("Invite User").withField("Invite").withBackgroundPrimaryAction());
      } else {
        if (this.model().panel().usedFor == "selection") {
          buttons.withChild(new SpacerDisplayRow().withWidth(2));
        } else {
          buttons
            .withChild(new ButtonDisplayRow()
              .withWidth("2").withName("Invite User").withField("Invite").withBackgroundPrimaryAction());
        }
      }
    }

    buttons
      .withChild(new ButtonDisplayRow()
        .withWidth("1").withName("Refresh").withField("Refresh"));

    if (this.model().panel().embedded) {
      buttons = null;
    }

    let all = query["state"] === "all";
    let active = query["state"] === "active" || !query["state"];
    if (this.model().isAdmin()) {
      all = query["state"] === "all" || !query["state"];
      active = query["state"] === "active";
    }

    let stateFilterRow = new RowDisplayRow()
      .withChild(new LabelValueDisplayRow().withWidth("1").withLabel("State"))
      .withChild(new ButtonDisplayRow().withWidth("1").withName("All").withKey("all").withField("State").withDefaultValue("all").withSelected(all))
      .withChild(new ButtonDisplayRow().withWidth("1").withName("Active").withKey("active").withField("State").withDefaultValue("active").withSelected(active))
      .withChild(new ButtonDisplayRow().withWidth("1").withName("Invited").withKey("inactive").withField("State").withDefaultValue("invited").withSelected(query["state"] === "invited"))
      .withChild(new ButtonDisplayRow().withWidth(this.model().panel().embedded ? 3 : 2).withName("Waiting to be Invited").withKey("pending").withField("State").withDefaultValue("notinvited").withSelected(query["state"] === "notinvited"))
      .withChild(new ButtonDisplayRow().withWidth("1").withName("Removed").withKey("inactive").withField("State").withDefaultValue("inactive").withSelected(query["state"] === "inactive"));

    stateFilterRow
      .withChild(new SpacerDisplayRow().withWidth(this.model().panel().embedded ? 7 : 8));

    let searchFilterRow = new RowDisplayRow()
      .withChild(new LabelValueDisplayRow().withWidth("1").withLabel("Search"))
      .withChild(new StringUpdateRow().withWidth("5")
        .withShowLabel(false).withField("Search")
        .withKey("Search").withName("Search")
        .withDefaultValue(query["search"] ? query["search"] : ""))
      .withChild(new ButtonDisplayRow().withWidth("1").withName("Go").withKey("Search").withField("Search").withSelected(false))
      .withChild(new ButtonDisplayRow().withWidth("1").withName("Clear").withKey("Search").withField("Clear").withSelected(false));

    searchFilterRow
      .withChild(new SpacerDisplayRow().withWidth("7"));


    this.addHeader(
      new RowDisplayRow()
        .withChild(
          new BoxDisplayRow()
            .withWidth("15")
            .withChild(buttons)
            .withChild(new RowDisplayRow()
              .withChild(
                new BoxDisplayRow()
                  .withWidth("15")
                  .withBorder(true)
                  .withChild(this.model().panel().embedded ? null : new LabelDisplayRow().withLabel("Filters"))
                  .withChild(stateFilterRow)
                  .withChild(searchFilterRow)
              )
            )
            .withChild(new RowDisplayRow()
              .withChild(new BoxDisplayRow()
                .withWidth("15")
                .withBorder(true)
                .withChild(new RowDisplayRow()
                  .withChild(new LabelValueDisplayRow().withWidth("4").withLabelWidth("5").withLabel("Showing").withValue(this.createPageInfo(totalItems, query, "employee")))
                  .withChild(new StringDisplayRow().withWidth("4"))
                  .withChild(new StringListUpdateRow().withWidth("3")
                    .withName("Items/Page")
                    .withField("PageSize")
                    .withShowLabel(false)
                    .withDefaultValue(this.model().pageSize(query, "employee"))
                    .withOptions(ContentUtils.PAGE_SIZES()))
                  .withChild(new ButtonDisplayRow().withWidth("1").withName("<").withField("PreviousPage"))
                  .withChild(new StringListUpdateRow().withWidth("2")
                    .withShowLabel(false)
                    .withName("Jump to Page")
                    .withField("PageIndex")
                    .withDefaultValue(query["page"] ? query["page"] : "1")
                    .withOptions(ContentUtils.PAGE_INDEXES(totalItems, this.model().pageSize(query, "employee"))))
                  .withChild(new ButtonDisplayRow().withWidth("1").withName(">").withField("NextPage"))
                )
              )
            )
        )
    );

    let box = new BoxDisplayRow();
    if (this.model().panel().usedFor == "selection") {
      box
      .withChild(new RowDisplayRow()
        .withBackgroundTitle()
        .withChild(new TitleDisplayRow().withWidth("4").withTitle("Name"))
        .withChild(new TitleDisplayRow().withWidth("4").withTitle("Email"))
        .withChild(new TitleDisplayRow().withWidth("2").withTitle("Employment Id"))
        .withChild(new TitleDisplayRow().withWidth("2").withTitle("Status"))
        .withChild(new TitleDisplayRow().withWidth("2").withTitle(""))
      );
    } else {
      box
      .withChild(new RowDisplayRow()
        .withBackgroundTitle()
        .withChild(new TitleDisplayRow().withWidth("5").withTitle("Details"))
        .withChild(new TitleDisplayRow().withWidth("5").withTitle("Address"))
        .withChild(new TitleDisplayRow().withWidth("5").withTitle("License & Compliance"))
      );
    }
    this.addHeader(box);
  }

}